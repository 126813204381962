import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.search";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import Head from '@/components/head/index';
import productDataListDialog from "./productDataListDialog.vue";
import DisplaySettingser from '@/components/DisplaySettings/Settings';
import { getCollegeList, getMajorList, productBatchEditStatus, getProductPageList } from '@/api/ceshi_xiugai/product';
var defaultDisplayValue = ["产品ID", "产品名称", "所属院校", "所属专业", "参考价格(元)", "产品类别", "认证类别", "产品状态", "是否需要出国", "产品管理员", "创建时间", "创建人", '操作'];
export default {
  name: 'producDataList',
  components: {
    Head: Head,
    DisplaySettingser: DisplaySettingser,
    productDataListDialog: productDataListDialog
  },
  data: function data() {
    return {
      // 产品数据列表
      list_type: 99,
      resultES: '',
      displayValue: [],
      displayArr: [],
      moment: moment,
      defaultDisplayValue: defaultDisplayValue,
      title: '产品数据列表',
      course: this.$route.query.course,
      MajorShow: true,
      search: {
        id: '',
        //产品id
        name: '',
        //产品名称
        status: '',
        auth_type: '',
        type: '',
        school_id: '',
        //院校id
        major_id: "" //专业id
      },
      key_word: '',
      key_wordList: '',
      pageList: 1,
      pagesizeList: 15,
      listLoading: false,
      query: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      tableData: [],
      selectionData: [],
      colleges: [],
      collegesErr: [],
      collegesArr: {},
      majorList: [],
      majorLists: []
    };
  },
  mounted: function mounted() {
    localStorage.setItem('names', JSON.stringify(defaultDisplayValue));
  },
  created: function created() {
    localStorage.setItem('names', JSON.stringify(defaultDisplayValue));
    if (this.course != 1) {
      this.getallSchoolList();
      this.getList(this.query.page, this.query.pagesize);
    }
  },
  methods: {
    QueryClick: function QueryClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.query.pagesize = val;
      this.getList(this.query.page, val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      this.getList(val, this.query.pagesize);
    },
    getList: function getList(page, pagesize) {
      var _this = this;
      this.listLoading = true;
      var obj = {};
      obj.page = page;
      obj.pagesize = pagesize;
      for (var key in this.search) {
        if (this.search[key] != '') {
          obj[key] = this.search[key];
        }
      }
      if (obj.status == 2) {
        obj.status = Number('0');
      }
      getProductPageList(obj).then(function (res) {
        var data = res.data;
        _this.tableData = data.data;
        _this.query.total = data.total;
        _this.listLoading = false;

        // 显示设置
        localStorage.setItem('names', []);
        localStorage.setItem('names', JSON.stringify(data.fields));
        _this.displayValue = data.fields;
        _this.displayArr = [];
        for (var _key in data.base_fields) {
          _this.displayArr.push(data.base_fields[_key]);
        }
      }).catch(function () {
        _this.tableData = [];
        _this.listLoading = false;
      });
    },
    // 新增
    add: function add() {
      this.$refs.productDataListDialog.openDialogFun(0);
    },
    // 编辑
    edit: function edit(row, type) {
      this.$refs.productDataListDialog.openDialogFun(1, row.id, type);
    },
    //  复选框操作
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionData = val;
    },
    // 批量发布, 批量下架
    batchRelease: function batchRelease(status, statusname) {
      var _this2 = this;
      if (this.selectionData.length < 1) {
        this.$message.error('请先选取要操作的数据');
        return;
      }
      // 操作的状态是否一致
      var lookstatus = this.selectionData.map(function (item, i) {
        return item.status;
      });
      var describe = '';
      // 允许不允许重新发布？
      if (statusname == '发布') {
        // 0下架状态
        if (lookstatus.includes(1)) {
          this.$message.error('发布的产品必须为下架状态');
          return;
        }
        describe = '确定发布？';
        var schoolLIst = [],
          majorelist = [],
          schoolFlag = false,
          majoreFlag = false;
        //status  0 删除 1 正常
        this.selectionData.forEach(function (item, i) {
          if (item.major && item.major.status !== '') {
            majorelist.push(item.major.status);
          }
          if (item.school && item.school.status !== '') {
            schoolLIst.push(item.school.status);
          }
        });
        if (schoolLIst.length > 0) {
          schoolFlag = schoolLIst.includes(0);
        }
        if (majorelist.length > 0) {
          majoreFlag = majorelist.includes(0);
        }
        if (schoolFlag || majoreFlag) {
          this.$message.error('已下架的产品，对应的院校或者专业已删除，不允许重新发布');
          return;
        }

        // 班级状态
        var classFlag = true;
        this.selectionData.forEach(function (item, i) {
          if (Number(item.deleted_classes_total) > 0) {
            classFlag = false;
          }
        });
        if (classFlag == false) {
          this.$message.error('已下架的产品，对应的班级有删除，不允许重新发布');
          return;
        }
      }
      if (statusname == '下架') {
        describe = '下架后不可再成交，确认下架？';
        // 1在售中状态
        if (lookstatus.includes(0)) {
          this.$message.error('下架的产品必须为在售中的状态');
          return;
        }
      }
      this.$confirm(describe, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var obj = {
          status: status,
          product_ids: _this2.selectionData.map(function (item, i) {
            return item.id;
          })
        };
        productBatchEditStatus(obj).then(function (res) {
          _this2.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
            duration: 2000
          });
          _this2.getList(_this2.query.page, _this2.query.pagesize);
        });
      }).catch(function () {});
    },
    // 单个发布下架
    sibatchRelease: function sibatchRelease(status, statusname, row) {
      var _this3 = this;
      var list = [];
      list.push(row.id);
      var describe = '';
      // status 0 下架 1 在售

      if (statusname == '发布' && row.status == '0') {
        describe = '确定发布？';
        if (row.major && row.major.status == 0 || row.school && row.school.status == 0) {
          // 0 删除 1 正常
          this.$message.error('已下架的产品，对应的院校或者专业已删除，不允许重新发布');
          return;
        }
        // 班级转态 大于0 不能发布
        if (row.deleted_classes_total && Number(row.deleted_classes_total) > 0) {
          this.$message.error('已下架的产品，对应的班级有删除，不允许重新发布');
          return;
        }
      }
      if (statusname == '下架') {
        describe = '下架后不可再成交，确认下架？';
      }
      this.$confirm(describe, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var obj = {
          status: status,
          product_ids: list
        };
        productBatchEditStatus(obj).then(function (res) {
          _this3.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
            duration: 2000
          });
          _this3.getList(_this3.query.page, _this3.query.pagesize);
        });
      }).catch(function () {});
    },
    // 所属院校
    dedupe: function dedupe(array) {
      //json去重数组方法
      var d = [];
      var hash = {};
      d = array.reduce(function (item, next) {
        hash[next.id] ? '' : hash[next.id] = true && item.push(next);
        return item;
      }, []);
      return d;
    },
    getallSchoolList: function getallSchoolList() {
      var _this4 = this;
      getCollegeList({
        page: this.pageList,
        pagesize: this.pagesizeList
      }).then(function (res) {
        res.data.school_list.forEach(function (item) {
          _this4.colleges.push(item);
          _this4.collegesErr.push(item);
        });
      });
    },
    schoolChange: function schoolChange(id) {
      var _this5 = this;
      if (id != '') {
        this.search.school_id = id;
        getMajorList({
          school_id: this.search.school_id,
          page: this.pageList,
          pagesize: this.pagesizeList,
          key_word: this.key_wordList
        }).then(function (res) {
          _this5.MajorShow = false;
          _this5.majorList = [];
          _this5.majorList = res.data.major_list;
          _this5.search.major_id = '';
        });
      }
    },
    remoteschoolMethod: function remoteschoolMethod(val) {
      var _this6 = this;
      this.key_word = val;
      this.pageList = 1;
      getCollegeList({
        key_word: this.key_word,
        page: this.pageList,
        pagesize: this.pagesizeList
      }).then(function (res) {
        var arr = [];
        res.data.school_list.forEach(function (item) {
          arr.push(item);
        });
        _this6.colleges = res.data.school_list;
        _this6.collegesErr = _this6.dedupe(arr);
        if (JSON.stringify(_this6.collegesArr) != '{}') {
          _this6.collegesErr.unshift(_this6.collegesArr);
        }
      });
    },
    CourtyardName: function CourtyardName() {
      var _this7 = this;
      if (this.pagesizeList != 0) {
        this.pageList++;
        getCollegeList({
          key_word: this.key_word,
          page: this.pageList,
          pagesize: this.pagesizeList
        }).then(function (res) {
          res.data.school_list.forEach(function (item) {
            _this7.colleges.push(item);
            _this7.collegesErr.push(item);
          });
          if (JSON.stringify(_this7.collegesArr) != '{}') {
            _this7.collegesErr.unshift(_this7.collegesArr);
            _this7.collegesErr = _this7.dedupe(_this7.collegesErr);
          }
        });
      }
    },
    // 专业
    MajorName: function MajorName() {
      var _this8 = this;
      if (this.pageList != 0) {
        this.pageList++;
        getMajorList({
          school_id: this.search.school_id,
          key_word: this.key_wordList,
          page: this.pageList,
          pagesize: this.pagesizeList
        }).then(function (res) {
          res.data.major_list.forEach(function (item) {
            _this8.majorList.push(item);
          });
        });
      }
    },
    MajorMethod: function MajorMethod(val) {
      var _this9 = this;
      this.key_wordList = val;
      this.pageList = 1;
      getMajorList({
        school_id: this.search.school_id,
        key_word: this.key_wordList,
        page: this.pageList,
        pagesize: this.pagesizeList
      }).then(function (res) {
        _this9.majorList = res.data.major_list;
      });
    },
    MajorFocus: function MajorFocus() {
      var _this10 = this;
      this.pageList = 1;
      getMajorList({
        school_id: this.search.school_id,
        page: this.pageList,
        pagesize: this.pagesizeList
      }).then(function (res) {
        _this10.majorLists = res.data.major_list;
      });
    },
    CourtyardClear: function CourtyardClear() {
      this.MajorShow = true;
      this.search.major_id = '';
      this.search.school_id = '';
    }
  }
};