import request from '@/utils/request';
//新增/编辑产品数据
export function editInfo(data) {
  return request({
    url: '/clientserver.php/Product/editInfo',
    method: 'post',
    data: data
  });
}

// 产品数据详情
export function getProductDetail(data) {
  return request({
    url: '/clientserver.php/Product/getProductDetail',
    method: 'post',
    data: data
  });
}

// 获取班级列表
export function getClassData(data) {
  return request({
    url: '/clientserver.php/ClassManagement/getClassData',
    method: 'post',
    data: data
  });
}