var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-dialog-class", attrs: { id: "mobileDialog" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogFormVisible,
            width: "1000px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品名称：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属院校：", prop: "school_id" } },
                [
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "loadmore",
                          rawName: "v-loadmore",
                          value: _vm.CourtyardName,
                          expression: "CourtyardName",
                        },
                      ],
                      attrs: {
                        remote: "",
                        filterable: "",
                        "remote-method": _vm.CourtyardMethod,
                        placeholder: "请选择",
                        clearable: "",
                      },
                      on: {
                        clear: function ($event) {
                          return _vm.onClearFun("school_id")
                        },
                        change: function (val) {
                          return _vm.MajorFocuss(val, "change")
                        },
                      },
                      model: {
                        value: _vm.form.school_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "school_id", $$v)
                        },
                        expression: "form.school_id",
                      },
                    },
                    _vm._l(_vm.collegeListOption, function (item) {
                      return _c("el-option", {
                        key: item.id + "collage",
                        attrs: {
                          label: item.title,
                          disabled: item.disabled,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属专业：", prop: "major_id" } },
                [
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "loadmore",
                          rawName: "v-loadmore",
                          value: _vm.MajorNames,
                          expression: "MajorNames",
                        },
                      ],
                      attrs: {
                        placeholder: "请选择",
                        "reserve-keyword": "",
                        remote: "",
                        filterable: "",
                        clearable: "",
                        "remote-method": _vm.MajorMethods,
                      },
                      on: {
                        clear: function ($event) {
                          return _vm.onClearFun("major_id")
                        },
                        focus: _vm.MajorFocuss,
                        change: _vm.majorChangeFun,
                      },
                      model: {
                        value: _vm.form.major_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "major_id", $$v)
                        },
                        expression: "form.major_id",
                      },
                    },
                    _vm._l(_vm.majorListOption, function (item) {
                      return _c("el-option", {
                        key: item.id + "major",
                        attrs: {
                          disabled: item.disabled,
                          label: item.title,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品参考价：", prop: "price" } },
                [
                  _c("el-input", {
                    staticClass: "mar-r-15",
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "price", $$v)
                      },
                      expression: "form.price",
                    },
                  }),
                  _vm._v("元\n            "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品类别：", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.typeListOption, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品管理员：", prop: "admin_id" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    on: { focus: _vm.mailList },
                    model: {
                      value: _vm.adminName,
                      callback: function ($$v) {
                        _vm.adminName = $$v
                      },
                      expression: "adminName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "认证类别：", prop: "auth_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.auth_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "auth_type", $$v)
                        },
                        expression: "form.auth_type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "可认证", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "不可认证", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否需要出国：", prop: "is_abroad" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.is_abroad,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_abroad", $$v)
                        },
                        expression: "form.is_abroad",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", {
                attrs: { label: "包含班级：", prop: "is_abroad" },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                _vm._l(_vm.class_list, function (item1, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "flex-form-class mar-bott-15" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-form-class mar-r-15" },
                        [
                          _c("span", { staticClass: "no-wrap-class" }, [
                            _vm._v("班级名称："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.class_list[index],
                                callback: function ($$v) {
                                  _vm.$set(_vm.class_list, index, $$v)
                                },
                                expression: "class_list[index]",
                              },
                            },
                            _vm._l(_vm.classListOption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  disabled: item.disabled,
                                  label: item.title,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-button", {
                        staticClass: "mar-r-15",
                        attrs: {
                          type: "primary",
                          icon: "el-icon-plus",
                          circle: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.pushOrShiftFun(index, 1)
                          },
                        },
                      }),
                      _vm._v(" "),
                      index != 0
                        ? _c("el-button", {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-minus",
                              circle: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.pushOrShiftFun(index, 0)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品卖点：" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 3 },
                      autocomplete: "off",
                      maxlength: "1024",
                    },
                    model: {
                      value: _vm.form.selling_point,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "selling_point", $$v)
                      },
                      expression: "form.selling_point",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "招生条件：" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 3 },
                      autocomplete: "off",
                      maxlength: "1024",
                    },
                    model: {
                      value: _vm.form.recruit_condition,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "recruit_condition", $$v)
                      },
                      expression: "form.recruit_condition",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "产品简章：" } }, [
                _c(
                  "div",
                  { staticClass: "flex-form-class" },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-uploader mar-r-15",
                        attrs: {
                          multiple: "",
                          "before-upload": _vm.beforeAvatarUpload,
                          "http-request": function (param) {
                            return _vm.uploadSectionFile(
                              param,
                              "product_brochure"
                            )
                          },
                          "show-file-list": false,
                          action: "action",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [
                            _vm._v("上传"),
                            _c("i", { staticClass: "el-icon-upload mar-l-10" }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("支持上传.docx .doc .pdf .txt .png .jpg格式文件"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "upload-content-class" },
                  _vm._l(_vm.form.product_brochure, function (item1, index1) {
                    return _c("div", { key: index1 }, [
                      item1.type == "jpg" ||
                      item1.type == "jpeg" ||
                      item1.type == "png"
                        ? _c(
                            "div",
                            { staticClass: "relative width-100 mar-bott-15" },
                            [
                              _c(
                                "div",
                                { staticClass: "width-100 img-hover-class" },
                                [
                                  _c("el-image", {
                                    staticClass: "width-100",
                                    attrs: {
                                      src: "" + _vm.imgUrl + item1.url,
                                      fit: "cover",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "img-class-mark" }, [
                                _c(
                                  "div",
                                  { staticClass: " absolute-center-class" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-zoom-in cursor-class",
                                      staticStyle: { "margin-right": "5px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onViewPicFun(
                                            "" + _vm.imgUrl + item1.url
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-delete cursor-class",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onPreviewFun(
                                            index1,
                                            0,
                                            "product_brochure"
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "flex-form-class other-file-class" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "file-name-class mar-r-15",
                                  attrs: { title: item1.name },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item1.name) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onPreviewFun(
                                            index1,
                                            1,
                                            "product_brochure",
                                            "" + _vm.imgUrl + item1.url
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("预览")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onPreviewFun(
                                            index1,
                                            0,
                                            "product_brochure"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "其他介绍：" } }, [
                _c(
                  "div",
                  { staticClass: "flex-form-class" },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-uploader mar-r-15",
                        attrs: {
                          multiple: "",
                          "before-upload": _vm.beforeAvatarUpload,
                          "http-request": function (param) {
                            return _vm.uploadSectionFile(
                              param,
                              "other_description"
                            )
                          },
                          "show-file-list": false,
                          action: "action",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [
                            _vm._v("上传"),
                            _c("i", { staticClass: "el-icon-upload mar-l-10" }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("支持上传.docx .doc .pdf .txt .png .jpg格式文件"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "upload-content-class" },
                  _vm._l(_vm.form.other_description, function (item1, index1) {
                    return _c("div", { key: index1 }, [
                      item1.type == "jpg" ||
                      item1.type == "jpeg" ||
                      item1.type == "png"
                        ? _c("div", {}, [
                            item1.type == "jpg" ||
                            item1.type == "jpeg" ||
                            item1.type == "png"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "relative width-100 mar-bott-15",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "width-100 img-hover-class",
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "width-100",
                                          attrs: {
                                            src: "" + _vm.imgUrl + item1.url,
                                            fit: "cover",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "img-class-mark" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              " absolute-center-class",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-zoom-in cursor-class",
                                              staticStyle: {
                                                "margin-right": "5px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onViewPicFun(
                                                    "" + _vm.imgUrl + item1.url
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-delete cursor-class",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onPreviewFun(
                                                    index1,
                                                    0,
                                                    "other_description"
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _c(
                            "div",
                            { staticClass: "flex-form-class other-file-class" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "file-name-class mar-r-15",
                                  attrs: { title: item1.name },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item1.name) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onPreviewFun(
                                            index1,
                                            1,
                                            "other_description",
                                            "" + _vm.imgUrl + item1.url
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("预览")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onPreviewFun(
                                            index1,
                                            0,
                                            "other_description"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.confirmLoading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("form")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "产品详情", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "row-border-class" }, [
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("产品ID"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm._v(_vm._s(_vm.formNew.id)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("产品名称"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm._v(_vm._s(_vm.formNew.name)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("所属院校"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm._v(_vm._s(_vm.formNew.school.school_title)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("院校所属国家地区"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm._v(_vm._s(_vm.formNew.school.province)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("所属专业"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm._v(_vm._s(_vm.formNew.major.major_title)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("产品参考价"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm._v(_vm._s(_vm.formNew.price)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("产品类别"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm.formNew.type == 0
                  ? _c("span", [_vm._v("全部")])
                  : _vm.formNew.type == 1
                  ? _c("span", [_vm._v("博士")])
                  : _vm.formNew.type == 2
                  ? _c("span", [_vm._v("硕士")])
                  : _vm.formNew.type == 3
                  ? _c("span", [_vm._v("本科")])
                  : _vm.formNew.type == 4
                  ? _c("span", [_vm._v("预科")])
                  : _vm.formNew.type == 5
                  ? _c("span", [_vm._v("硕士预科")])
                  : _vm.formNew.type == 6
                  ? _c("span", [_vm._v("研修班")])
                  : _vm.formNew.type == 7
                  ? _c("span", [_vm._v("培训班")])
                  : _c("span"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("产品学制"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm._v(_vm._s(_vm.formNew.major.major_year) + "年"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("认证类别"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm.formNew.auth_type == 0
                  ? _c("span", [_vm._v("全部")])
                  : _vm.formNew.auth_type == 1
                  ? _c("span", [_vm._v("可认证")])
                  : _vm.formNew.auth_type == 2
                  ? _c("span", [_vm._v("不可认证")])
                  : _c("span"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("产品管理员"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm._v(_vm._s(_vm.formNew.product_manage_name)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("是否需要出国"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _vm.formNew.status == 1
                  ? _c("span", [_vm._v("是")])
                  : _c("span", [_vm._v("否")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("包含班级"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                _vm._l(_vm.formNew.class_list, function (item, index) {
                  return _c("div", { key: index }, [
                    _vm._v(
                      "\n                        班级名称：" +
                        _vm._s(item.name) +
                        "\n                    "
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("产品卖点"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _c("div", {
                  staticStyle: { "white-space": "pre-wrap" },
                  domProps: { innerHTML: _vm._s(_vm.formNew.selling_point) },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("招生条件"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _c("div", {
                  staticStyle: { "white-space": "pre-wrap" },
                  domProps: {
                    innerHTML: _vm._s(_vm.formNew.recruit_condition),
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("产品简章"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _c(
                  "div",
                  { staticClass: "upload-content-class" },
                  _vm._l(
                    _vm.formNew.product_brochure,
                    function (item1, index1) {
                      return _c("div", { key: index1 }, [
                        item1.type == "jpg" ||
                        item1.type == "jpeg" ||
                        item1.type == "png"
                          ? _c("div", {}, [
                              item1.type == "jpg" ||
                              item1.type == "jpeg" ||
                              item1.type == "png"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "relative width-100 mar-bott-15",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "width-100 img-hover-class",
                                        },
                                        [
                                          _c("el-image", {
                                            staticClass: "width-100",
                                            attrs: {
                                              src: "" + _vm.imgUrl + item1.url,
                                              fit: "cover",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "img-class-mark" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                " absolute-center-class",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-zoom-in cursor-class",
                                                staticStyle: {
                                                  "margin-right": "5px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onViewPicFun(
                                                      "" +
                                                        _vm.imgUrl +
                                                        item1.url
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "flex-form-class align-center-class other-file-class",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "file-name-class mar-r-15",
                                    attrs: { title: item1.name },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(item1.name) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onPreviewFun(
                                              index1,
                                              1,
                                              "product_brochure",
                                              "" + _vm.imgUrl + item1.url
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("预览")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-class" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("其他介绍"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple-light" }, [
                _c(
                  "div",
                  { staticClass: "upload-content-class" },
                  _vm._l(_vm.form.other_description, function (item1, index1) {
                    return _c("div", { key: index1 }, [
                      item1.type == "jpg" ||
                      item1.type == "jpeg" ||
                      item1.type == "png"
                        ? _c("div", {}, [
                            item1.type == "jpg" ||
                            item1.type == "jpeg" ||
                            item1.type == "png"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "relative width-100 mar-bott-15",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "width-100 img-hover-class",
                                      },
                                      [
                                        _c("el-image", {
                                          staticClass: "width-100",
                                          attrs: {
                                            src: "" + _vm.imgUrl + item1.url,
                                            fit: "cover",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "img-class-mark" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              " absolute-center-class",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-zoom-in cursor-class",
                                              staticStyle: {
                                                "margin-right": "5px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onViewPicFun(
                                                    "" + _vm.imgUrl + item1.url
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _c(
                            "div",
                            {
                              staticClass:
                                "flex-form-class align-center-class other-file-class",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "file-name-class mar-r-15",
                                  attrs: { title: item1.name },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(item1.name) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onPreviewFun(
                                            index1,
                                            1,
                                            "other_description",
                                            "" + _vm.imgUrl + item1.url
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("预览")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片放大",
            visible: _vm.dialogPicVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPicVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {},
            [
              _c("el-image", {
                staticClass: "perview-url-class",
                attrs: { src: _vm.perviewUrl },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogPicVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("mailListEs", {
        ref: "staffSelect",
        attrs: {
          radio: _vm.radioEs,
          text: _vm.text,
          condition: _vm.condition,
          visible: _vm.mailListVisibleEs,
        },
        on: {
          "update:visible": function ($event) {
            _vm.mailListVisibleEs = $event
          },
          staffSelectConfirmEs: _vm.staffSelectConfirmEs,
          staffSelectCancel: _vm.staffSelectCancel,
        },
        model: {
          value: _vm.staffResultEs,
          callback: function ($$v) {
            _vm.staffResultEs = $$v
          },
          expression: "staffResultEs",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }