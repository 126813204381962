import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Directionatta, getMajorList, getCollegeList, MajorRead } from '@/api/ceshi_xiugai/product';
import { editInfo, getProductDetail, getClassData } from '@/api/ceshi_xiugai/productNew';
import mailListEs from '@/components/StaffSelect/mailListRadioEs1';
export default {
  data: function data() {
    return {
      dialogFormVisible: false,
      class_list: [''],
      form: {
        name: '',
        school_id: '',
        major_id: '',
        price: '',
        type: '',
        admin_id: '',
        auth_type: '',
        is_abroad: '',
        selling_point: '',
        recruit_condition: '',
        product_brochure: [],
        other_description: [],
        status: 1,
        class_ids: ''
      },
      formNew: {
        name: '',
        school_id: '',
        major_id: '',
        price: '',
        type: '',
        admin_id: '',
        auth_type: '',
        is_abroad: '',
        selling_point: '',
        recruit_condition: '',
        product_brochure: [],
        other_description: [],
        status: 1,
        class_ids: '',
        school: {
          province: "",
          school_title: ""
        },
        major: {
          major_title: "",
          major_year: ''
        }
      },
      rules: {
        name: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }, {
          min: 1,
          max: 100,
          message: '长度在 1 到 100 个字符',
          trigger: 'blur'
        }],
        school_id: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        major_id: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        admin_id: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        auth_type: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        is_abroad: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        price: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }]
      },
      formLabelWidth: '140px',
      dialogTableVisible: false,
      // 院校专业下拉列表
      collegeListOption: [],
      majorListOption: [],
      classListOption: [],
      collegeArr: {},
      majorArr: {},
      // 产品管理员
      proAdminListOption: [],
      scree: {
        page: 1,
        pagesize: 15,
        key_word: '',
        pageList: 1,
        pagesizeList: 15,
        key_wordList: ''
      },
      // 产品类别 1-博士 2-硕士 3-本科 4-预科 5-硕士预科 6-研修班 7-培训班
      typeListOption: [{
        id: 1,
        title: '博士'
      }, {
        id: 2,
        title: '硕士'
      }, {
        id: 3,
        title: '本科'
      }, {
        id: 4,
        title: '预科'
      }, {
        id: 5,
        title: '硕士预科'
      }, {
        id: 6,
        title: '研修班'
      }, {
        id: 7,
        title: '培训班'
      }],
      adminName: '',
      dialogTitle: '新建产品信息',
      // 组件变量
      condition: [],
      staffResultEs: [],
      mailListVisibleEs: false,
      radioEs: false,
      disabledShow: false,
      text: [],
      textWatch: [],
      // ------
      confirmLoading: false,
      imgUrl: process.env.VUE_APP_BASE_API2,
      dialogPicVisible: false,
      perviewUrl: ''
    };
  },
  components: {
    mailListEs: mailListEs
  },
  mounted: function mounted() {

    // this.openDialogFun(1,9,'edit')
  },
  methods: {
    openDialogFun: function openDialogFun() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var id = arguments.length > 1 ? arguments[1] : undefined;
      var typeNew = arguments.length > 2 ? arguments[2] : undefined;
      if (type == 0) {
        this.CourtyardName(1);
        // 新增
        this.dialogTitle = '新建产品信息';
        this.dialogFormVisible = true;
      } else {
        this.dialogTitle = '编辑产品信息';
        this.getInfoFun(id, typeNew);
      }
    },
    handleClose: function handleClose() {
      this.form = {
        name: '',
        school_id: '',
        major_id: '',
        price: '',
        type: '',
        admin_id: '',
        auth_type: '',
        is_abroad: '',
        selling_point: '',
        recruit_condition: '',
        product_brochure: [],
        other_description: [],
        status: 1,
        class_ids: ''
      };
      this.scree.page = 1;
      this.scree.pagesize = 15;
      this.scree.key_word = '';
      this.scree.pageList = 1;
      this.scree.pagesizeList = 15;
      this.scree.key_wordList = '';
      this.collegeListOption = [];
      this.majorListOption = [];
      this.classListOption = [];
      // 产品管理员
      this.proAdminListOption = [];
      this.adminName = '';
      this.text = [];
      this.collegeArr = {};
      this.majorArr = {};
      this.class_list = [];
      this.$refs['form'].resetFields();
      this.dialogFormVisible = false;
    },
    pushOrShiftFun: function pushOrShiftFun(index, type) {
      if (type == 0) {
        // 0是删除
        this.class_list.splice(index, 1);
      } else {
        // 新增
        this.class_list.splice(index + 1, 0, '');
      }
    },
    // 文件上传接口
    //点击上传
    uploadSectionFile: function uploadSectionFile(param, type) {
      var _this = this;
      var fileName = param.file.name;
      var index = fileName.lastIndexOf(".");
      var ext = fileName.substr(index + 1);
      var newfile = new FormData();
      newfile.append('file', param.file);
      var num = '200020';
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png') {
        num = '200020';
      } else {
        num = '200021';
      }
      newfile.append('type', num);
      Directionatta(newfile).then(function (res) {
        _this.form[type].push({
          name: fileName,
          url: res.data.path,
          type: ext
        });
      });
    },
    // 文件上传格式校验
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var filename = file.name;
      var index = filename.lastIndexOf(".");
      var ext = filename.substr(index + 1);
      var isYes = ext == 'docx' || ext == 'doc' || ext == 'pdf' || ext == 'txt' || ext == 'jpg' || ext == 'jpeg' || ext == 'png';
      if (!isYes) {
        this.$message.error('只支持上传.docx .doc .pdf .txt .png .jpg格式文件');
        return false;
      }
    },
    // 文件预览加删除
    onPreviewFun: function onPreviewFun(index, type, column, href) {
      if (type == 1) {
        console.log('hhhhh', href);
        // 预览
        window.open(href, '_blank');
      } else {
        // 删除
        this.form[column].splice(index, 1);
      }
    },
    onViewPicFun: function onViewPicFun(href) {
      this.dialogPicVisible = true;
      this.perviewUrl = href;
    },
    // 获取内容详情
    getInfoFun: function getInfoFun(id, type) {
      var _this2 = this;
      getProductDetail({
        id: id
      }).then(function (res) {
        var data = res.data;
        _this2.form = {
          id: data.id,
          name: data.name,
          school_id: data.school_id,
          major_id: data.major_id,
          price: data.price,
          type: data.type,
          admin_id: data.admin_id,
          auth_type: data.auth_type,
          is_abroad: data.is_abroad,
          selling_point: data.selling_point,
          recruit_condition: data.recruit_condition,
          product_brochure: data.product_brochure ? data.product_brochure : [],
          other_description: data.other_description ? data.other_description : [],
          status: data.status,
          class_ids: data.class_ids
        };
        // 班级数组
        _this2.class_list = data.class_ids.split(',');
        var arr = _this2.class_list.map(function (item) {
          return item * 1;
        });
        _this2.class_list = arr;
        // 详情回显
        _this2.formNew = res.data;
        if (type == 'edit') {
          _this2.dialogFormVisible = true;
        } else {
          _this2.dialogTableVisible = true;
        }
        // 管理员名称赋值
        _this2.adminName = res.data.product_manage_name;
        _this2.text.push(data.admin_id);
        _this2.CourtyardName(1);
        _this2.MajorFocuss();
        // 获取班级的列表
        // 处理班级删除回显
        var arr_class = [];
        _this2.formNew.class_list.forEach(function (val) {
          if (val.status == 0) {
            arr_class.push({
              id: val.id,
              title: val.name,
              disabled: true
            });
          }
        });
        getClassData({
          colleges_id: _this2.form.school_id,
          major_id: _this2.form.major_id,
          is_charges: 2
        }).then(function (res) {
          _this2.classListOption = res.data;
          arr_class.forEach(function (val) {
            _this2.classListOption.unshift(val);
          });
          _this2.classListOption = _this2.dedupe(_this2.classListOption);
        });
        // 为了回显 处理数据
        _this2.collegesArr = {
          id: data.school.id,
          title: data.school.school_title,
          // 删除的数据不可再次选中
          disabled: data.school.status == 0 ? true : false
        };
        _this2.collegeListOption.unshift(_this2.collegesArr);
        _this2.collegeListOption = _this2.dedupe(_this2.collegeListOption);
        _this2.majorListsArr = {
          id: data.major.id,
          title: data.major.major_title,
          // 删除的数据不可再次选中
          disabled: data.major.status == 0 ? true : false
        };
        _this2.majorListOption.unshift(_this2.majorListsArr);
        _this2.majorListOption = _this2.dedupe(_this2.majorListOption);
      });
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          for (var i = 0; i < _this3.class_list.length; i++) {
            if (_this3.class_list[i] == '') {
              _this3.$message({
                message: '请检查班级信息是否完整',
                type: 'warning'
              });
              return;
            }
          }
          var narr1 = _toConsumableArray(new Set(_this3.class_list));
          if (_this3.class_list.length != narr1.length) {
            _this3.$message({
              message: '班级信息有重复 请检查',
              type: 'warning'
            });
            return;
          }
          var reg = /^(([0-9]+)|([0-9]+\.[0-9]{0,2}))$/;
          if (!reg.test(_this3.form.price)) {
            _this3.$message({
              message: '产品参考价必须是数字，且最多保留两位小数',
              type: 'warning'
            });
            return;
          }
          _this3.confirmLoading = true;
          _this3.$set(_this3.form, 'class_ids', _this3.class_list.toString());
          editInfo(_this3.form).then(function (res) {
            _this3.dialogFormVisible = false;
            var str = '新建成功';
            if (_this3.form.id) {
              str = '修改成功';
            }
            _this3.$message({
              message: str,
              type: 'success'
            });
            _this3.$emit('getList');
          }).finally(function () {
            _this3.confirmLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getSchool: function getSchool() {
      var _this4 = this;
      getCollegeList({
        page: this.scree.page,
        pagesize: this.scree.pagesize
      }).then(function (res) {
        _this4.collegeListOption = res.data.school_list;
      });
    },
    CourtyardName: function CourtyardName(index) {
      var _this5 = this;
      if (this.scree.pagesize != 0) {
        if (index == 1) {
          this.scree.page = 1;
        } else {
          this.scree.page++;
        }
        getCollegeList({
          key_word: this.scree.key_word,
          page: this.scree.page,
          pagesize: this.scree.pagesize
        }).then(function (res) {
          res.data.school_list.forEach(function (item) {
            _this5.collegeListOption.push(item);
          });
          _this5.collegeListOption = _this5.dedupe(_this5.collegeListOption);
          // this.collegeListOption.concat(res.data.school_list)
          // console.log(res.data.school_list)
        });
      }
    },
    CourtyardMethod: function CourtyardMethod(val) {
      var _this6 = this;
      this.scree.key_word = val;
      this.scree.page = 1;
      getCollegeList({
        key_word: this.scree.key_word,
        page: this.scree.page,
        pagesize: this.scree.pagesize
      }).then(function (res) {
        if (_this6.scree.key_word != '') {
          _this6.collegeListOption = res.data.school_list;
        } else {
          // this.collegeListOption.concat(res.data.school_list)
          res.data.school_list.forEach(function (item) {
            _this6.collegeListOption.push(item);
          });
          _this6.collegeListOption = _this6.dedupe(_this6.collegeListOption);
        }
      });
    },
    // 切换院校
    // changeCollageListFun(val){
    //     this.scree.key_word = ''
    //     getMajorList({
    //         school_id: val,
    //         key_word: this.scree.key_word,
    //         page: this.scree.page,
    //         pagesize: this.scree.pagesize
    //     }).then((res) => {
    //         console.log('88888888')
    //         this.majorListOption = res.data.major_list;
    //     });
    // },
    MajorNames: function MajorNames() {
      var _this7 = this;
      if (!this.form.school_id) {
        return;
      }
      this.scree.pageList++;
      getMajorList({
        school_id: this.form.school_id,
        key_word: this.scree.key_wordList,
        page: this.scree.pageList,
        pagesize: this.scree.pagesizeList
      }).then(function (res) {
        if (_this7.scree.key_word != '') {
          _this7.majorListOption = res.data.school_list;
        } else {
          res.data.major_list.forEach(function (item) {
            _this7.majorListOption.push(item);
          });
          _this7.majorListOption = _this7.dedupe(_this7.majorListOption);
        }
      });
    },
    MajorMethod: function MajorMethod(val) {
      var _this8 = this;
      if (!this.form.school_id) {
        return;
      }
      this.scree.key_wordList = val;
      this.scree.pageList = 1;
      getMajorList({
        school_id: this.form.school_id,
        key_word: this.scree.key_wordList,
        page: this.scree.pageList,
        pagesize: this.scree.pagesizeList
      }).then(function (res) {
        _this8.majorListOption = res.data.major_list;
      });
    },
    MajorMethods: function MajorMethods(val) {
      var _this9 = this;
      if (!this.form.school_id) {
        return;
      }
      this.scree.key_wordList = val;
      this.scree.pageList = 1;
      getMajorList({
        school_id: this.form.school_id,
        key_word: this.scree.key_wordList,
        page: this.scree.pageList,
        pagesize: this.scree.pagesizeList
      }).then(function (res) {
        if (_this9.scree.key_word != '') {
          _this9.majorListOption = res.data.school_list;
        } else {
          res.data.major_list.forEach(function (item) {
            _this9.majorListOption.push(item);
          });
          _this9.majorListOption = _this9.dedupe(_this9.majorListOption);
        }
      });
    },
    MajorFocuss: function MajorFocuss(val, type) {
      var _this10 = this;
      if (!this.form.school_id) {
        return;
      }
      this.scree.pageList = 1;
      getMajorList({
        school_id: this.form.school_id,
        page: this.scree.pageList,
        pagesize: this.scree.pagesizeList
      }).then(function (res) {
        if (type == 'change') {
          _this10.form.major_id = '';
          _this10.majorListOption = [];
          _this10.majorListOption = res.data.major_list;
          _this10.adminName = '';
          _this10.form.admin_id = '';
          _this10.classListOption = [];
          _this10.form.class_ids = '';
          _this10.class_list = [''];
        } else {
          res.data.major_list.forEach(function (item) {
            _this10.majorListOption.push(item);
          });
          _this10.majorListOption = _this10.dedupe(_this10.majorListOption);
        }
      });
    },
    // 去重方法
    dedupe: function dedupe(array) {
      //json去重数组方法
      var d = [];
      var hash = {};
      d = array.reduce(function (item, next) {
        hash[next.id] ? '' : hash[next.id] = true && item.push(next);
        return item;
      }, []);
      return d;
    },
    majorChangeFun: function majorChangeFun(val) {
      var _this11 = this;
      this.form.class_ids = '';
      this.class_list = [''];
      if (val != '') {
        MajorRead({
          id: val
        }).then(function (res) {
          _this11.adminName = res.data.maior_admin.realname;
          _this11.form.admin_id = res.data.maior_admin.id;
        });
        getClassData({
          colleges_id: this.form.school_id,
          major_id: val,
          is_charges: 2
        }).then(function (res) {
          _this11.classListOption = res.data;
        });
      } else {
        this.adminName = '';
        this.form.admin_id = '';
      }
    },
    // 产品管理员
    mailList: function mailList() {
      this.text = this.textWatch;
      this.radioEs = true;
      this.mailListVisibleEs = true;
      this.disabledShow = true;
    },
    staffSelectConfirmEs: function staffSelectConfirmEs(resultNameArr, result) {
      this.adminName = String(resultNameArr);
      this.form.admin_id = String(result);
      this.disabledShow = false;
      this.textWatch = result;
      this.text = [];
    },
    staffSelectCancel: function staffSelectCancel() {},
    onClearFun: function onClearFun(column) {
      this.form[column] = '';
      this.form.major_id = '';
      this.adminName = '';
      this.form.admin_id = '';
      this.classListOption = [];
      this.form.class_ids = '';
      this.class_list = [''];
      if (column == 'school_id') {
        this.collegeListOption = [];
        this.majorListOption = [];
        this.scree = {
          page: 1,
          pagesize: 15,
          key_word: '',
          pageList: 1,
          pagesizeList: 15,
          key_wordList: ''
        };
        this.CourtyardName(1);
      } else {
        this.scree.pageList = 1;
        this.scree.pagesizeList = 15;
        this.scree.key_wordList = '';
      }
    },
    preText: function preText(pretext) {
      return pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;');
    }
  }
};