var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticClass: "hrsdbox", staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { height: "40px", margin: "0px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 } },
                      [
                        _c("span", [_vm._v("产品ID:")]),
                        _vm._v(" "),
                        _c("el-input", {
                          attrs: {
                            placeholder: "产品id",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.search.id,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "id", $$v)
                            },
                            expression: "search.id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 } },
                      [
                        _c("span", [_vm._v("产品名称:")]),
                        _vm._v(" "),
                        _c("el-input", {
                          attrs: {
                            placeholder: "产品名称",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.search.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "name", $$v)
                            },
                            expression: "search.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 } },
                      [
                        _c("span", [_vm._v("所属院校专业:")]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "loadmore",
                                rawName: "v-loadmore",
                                value: _vm.CourtyardName,
                                expression: "CourtyardName",
                              },
                            ],
                            attrs: {
                              placeholder: "请选择",
                              "reserve-keyword": "",
                              remote: "",
                              filterable: "",
                              clearable: "",
                              size: "small",
                              "remote-method": _vm.remoteschoolMethod,
                            },
                            on: {
                              clear: _vm.CourtyardClear,
                              change: _vm.schoolChange,
                            },
                            model: {
                              value: _vm.search.school_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "school_id", $$v)
                              },
                              expression: "search.school_id",
                            },
                          },
                          _vm._l(_vm.colleges, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.title, value: item.id },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "loadmore",
                                rawName: "v-loadmore",
                                value: _vm.MajorName,
                                expression: "MajorName",
                              },
                            ],
                            attrs: {
                              placeholder: "请选择",
                              "reserve-keyword": "",
                              remote: "",
                              filterable: "",
                              "remote-method": _vm.MajorMethod,
                              size: "small",
                              disabled: _vm.MajorShow,
                            },
                            on: { focus: _vm.MajorFocus },
                            model: {
                              value: _vm.search.major_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "major_id", $$v)
                              },
                              expression: "search.major_id",
                            },
                          },
                          _vm._l(_vm.majorList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.title, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 } },
                      [
                        _c("span", [_vm._v("产品类别:")]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "请选择产品类别",
                              clearable: "",
                            },
                            model: {
                              value: _vm.search.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "type", $$v)
                              },
                              expression: "search.type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "0", label: "全部" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "1", label: "博士" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "2", label: "硕士" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "3", label: "本科" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "4", label: "预科" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "5", label: "硕士预科" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "6", label: "研修班" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "7", label: "培训班" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 } },
                      [
                        _c("span", [_vm._v("认证类别:")]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              placeholder: "请选择认证类别",
                              clearable: "",
                              size: "small",
                            },
                            model: {
                              value: _vm.search.auth_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "auth_type", $$v)
                              },
                              expression: "search.auth_type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "0", label: "全部" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "1", label: "可认证" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "2", label: "不可认证" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 } },
                      [
                        _c("span", [_vm._v("产品状态:")]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              placeholder: "请选择产品状态",
                              clearable: "",
                              size: "small",
                            },
                            model: {
                              value: _vm.search.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "status", $$v)
                              },
                              expression: "search.status",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: -1, label: "全部" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 2, label: "下架" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 1, label: "在售中" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 } },
                      [
                        _c("display-settingser", {
                          staticClass: "ml10 filter-item btnse-li mar-bott-20",
                          attrs: {
                            "display-arr": _vm.displayArr,
                            "display-value": _vm.displayValue,
                            "default-value": _vm.defaultDisplayValue,
                            list_type: _vm.list_type,
                          },
                          on: {
                            "update:displayValue": function ($event) {
                              _vm.displayValue = $event
                            },
                            "update:display-value": function ($event) {
                              _vm.displayValue = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 12, md: 12 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.QueryClick },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新增/编辑"],
                                expression: "['新增/编辑']",
                              },
                            ],
                            staticClass: "releasebtn",
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.add },
                          },
                          [_vm._v("新增")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["发布/下架"],
                                expression: "['发布/下架']",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.batchRelease("1", "发布")
                              },
                            },
                          },
                          [_vm._v("批量发布")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["发布/下架"],
                                expression: "['发布/下架']",
                              },
                            ],
                            staticClass: "puupbtn",
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.batchRelease("0", "下架")
                              },
                            },
                          },
                          [_vm._v("批量下架")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                ref: "tb",
                staticStyle: { width: "100%" },
                attrs: {
                  border: "",
                  "header-cell-style": {
                    background: "#F9F9F9",
                    color: "#222222",
                  },
                  data: _vm.tableData,
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", align: "center" },
                }),
                _vm._v(" "),
                _vm.displayValue.includes("产品ID")
                  ? _c("el-table-column", {
                      key: 1,
                      attrs: { prop: "id", label: "产品ID", align: "center" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("产品名称")
                  ? _c("el-table-column", {
                      key: 2,
                      attrs: {
                        prop: "name",
                        label: "产品名称",
                        align: "center",
                        width: "200",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "#1890FF" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(scope.row, "info")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.name))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3615410735
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("所属院校")
                  ? _c("el-table-column", {
                      key: 3,
                      attrs: {
                        prop: "school.school_title",
                        label: "所属院校",
                        align: "center",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("所属专业")
                  ? _c("el-table-column", {
                      key: 4,
                      attrs: {
                        prop: "major.major_title",
                        label: "所属专业",
                        align: "center",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("参考价格(元)")
                  ? _c("el-table-column", {
                      key: 5,
                      attrs: {
                        prop: "price",
                        label: "参考价格(元)",
                        align: "center",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("产品类别")
                  ? _c("el-table-column", {
                      key: 6,
                      attrs: {
                        prop: "type",
                        label: "产品类别",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.type == 0
                                  ? _c("span", [_vm._v("全部")])
                                  : scope.row.type == 1
                                  ? _c("span", [_vm._v("博士")])
                                  : scope.row.type == 2
                                  ? _c("span", [_vm._v("硕士")])
                                  : scope.row.type == 3
                                  ? _c("span", [_vm._v("本科")])
                                  : scope.row.type == 4
                                  ? _c("span", [_vm._v("预科")])
                                  : scope.row.type == 5
                                  ? _c("span", [_vm._v("硕士预科")])
                                  : scope.row.type == 6
                                  ? _c("span", [_vm._v("研修班")])
                                  : scope.row.type == 7
                                  ? _c("span", [_vm._v("培训班")])
                                  : _c("span"),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1234327691
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("认证类别")
                  ? _c("el-table-column", {
                      key: 7,
                      attrs: {
                        prop: "auth_type",
                        label: "认证类别",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.auth_type == 0
                                  ? _c("span", [_vm._v("全部")])
                                  : scope.row.auth_type == 1
                                  ? _c("span", [_vm._v("可认证")])
                                  : scope.row.auth_type == 2
                                  ? _c("span", [_vm._v("不可认证")])
                                  : _c("span"),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3209894191
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("产品状态")
                  ? _c("el-table-column", {
                      key: 8,
                      attrs: {
                        prop: "status",
                        label: "产品状态",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == -1
                                  ? _c("span", [_vm._v("全部")])
                                  : scope.row.status == 0
                                  ? _c("span", [_vm._v("下架")])
                                  : scope.row.status == 1
                                  ? _c("span", [_vm._v("在售中")])
                                  : _c("span"),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1726984001
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("是否需要出国")
                  ? _c("el-table-column", {
                      key: 9,
                      attrs: {
                        prop: "is_abroad",
                        label: "是否需要出国",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status == 1
                                  ? _c("span", [_vm._v("是")])
                                  : _c("span", [_vm._v("否")]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        615648656
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("产品管理员")
                  ? _c("el-table-column", {
                      key: 10,
                      attrs: {
                        prop: "product_manage_name",
                        label: "产品管理员",
                        align: "center",
                        width: "200",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("创建时间")
                  ? _c("el-table-column", {
                      key: 11,
                      attrs: {
                        prop: "create_time",
                        label: "创建时间",
                        align: "center",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayValue.includes("创建人")
                  ? _c("el-table-column", {
                      key: 12,
                      attrs: {
                        prop: "creator_name",
                        label: "创建人",
                        align: "center",
                        width: "140",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { fixed: "right", label: "操作", width: "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["新增/编辑"],
                                    expression: "['新增/编辑']",
                                  },
                                ],
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row, "edit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["发布/下架"],
                                    expression: "['发布/下架']",
                                  },
                                ],
                              },
                              [
                                scope.row.status == "0"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "lnup",
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sibatchRelease(
                                              "1",
                                              "发布",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("发布")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["发布/下架"],
                                    expression: "['发布/下架']",
                                  },
                                ],
                              },
                              [
                                scope.row.status == "1"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "linxiaja",
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.sibatchRelease(
                                              "0",
                                              "下架",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("下架")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1197438404
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c("product-data-list-dialog", {
              ref: "productDataListDialog",
              on: { getList: _vm.QueryClick },
            }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }